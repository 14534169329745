// BlinkingIcon.tsx
import React from 'react';
import './BlinkingIcon.scss';
import LogoIcon from '../../../assets/images/logo.png';

const BlinkingIcon: React.FC = () => {
  return (
    <div className="blinking-icon">
      <img src={LogoIcon} alt="Loading..." />
    </div>
  );
};

export default BlinkingIcon;
