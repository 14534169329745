// App.tsx
import React, { useState, useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import './App.css';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { HomePage } from './pages/HomePage';
import { HRSolutonPage } from './pages/HRSolution';
import { OverseasConsultantPage } from './pages/OverseasConsultant';
import { InstitutionsPage } from './pages/Institutions';
import { ContactUsPage } from './pages/ContactUs';
import Loading from './components/Loading';

// Define the prop type for NewTabRedirect
interface NewTabRedirectProps {
  to: string;
}

// Create a component that will handle opening a new tab
const NewTabRedirect: React.FC<NewTabRedirectProps> = ({ to }) => {
  React.useEffect(() => {
    window.open(to, '_blank');
  }, [to]);

  return <Navigate replace to="/home" />;
};

function App() {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <div className='page-wrapper'>
          <Routes>
            <Route path="/*" element={<Navigate replace to="/home" />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/hr-solution" element={<HRSolutonPage />} />
            <Route path="/overseas-consultant" element={<OverseasConsultantPage />} />
            <Route path="/institutions" element={<InstitutionsPage />} />
            <Route path="/contact-us" element={<NewTabRedirect to="/contact" />} />
            <Route path="/contact" element={<ContactUsPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
