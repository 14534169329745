import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

import './styles.scss'

export const SocialMediaIcons: React.FC = () => {
    return (
        <div className='social-media-icons-wrapper'>
            <a target='_blank' rel='noreferrer' href='https://www.facebook.com/profile.php?id=61552690604058&mibextid=LQQJ4d' ><FacebookIcon /></a>
            <a target='_blank' rel='noreferrer' href='https://www.instagram.com/biz.lancer/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==' ><InstagramIcon /> </a>
            <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/in/bizlancer-llc-b65903298' > <LinkedInIcon /> </a>
            <a target='_blank' rel='noreferrer' href='https://x.com/infobizlancer?s=11' > <TwitterIcon /> </a>
        </div>
    )
}
