import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { TextField, Button } from '@mui/material';
import './styles.scss'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface FormValues {
  user_name: string;
  // lastName: string;
  user_email: string;
  subject: string;
  message: string;
}
export const ContactUsPage = () => {
  const form: any = useRef();
  const [emailData, setEmailData] = useState<FormValues>({
    user_name: '',
    // lastName: '',
    user_email: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    user_name: false,
    // lastName: false,
    user_email: false,
    subject: false,
    message: false
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setEmailData({ ...emailData, [name]: value });
    setErrors({ ...errors, [name]: value.trim() === '' });
  };

  const sendEmail = (e: any) => {
    e.preventDefault();

    const hasErrors = Object.values(emailData).some(value => value.trim() === '');
    if (hasErrors) {
      // Set error for each field that is empty
      const newErrors: { [key in keyof FormValues]: boolean } = {} as any;
      for (const key in emailData) {
        if (emailData[key as keyof FormValues].trim() === '') {
          newErrors[key as keyof FormValues] = true;
        } else {
          newErrors[key as keyof FormValues] = false;
        }
      }
      setErrors(newErrors);
      return;
    }
    emailjs
      .sendForm('service_bm1v0vp', 'template_jdal5ue', form.current, {
        publicKey: 'tZdoxAbFehJyLVcOx',
      })
      .then(
        () => {
          toast.success("Feedback Mail send successfully!", {
            position: "top-right"
          });
          setEmailData({ user_name: '', user_email: '', subject: '', message: '' });
        },
        (error) => {
          toast.info(error.message, {
            position: "top-right"
          });
        },
      );
  };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
    scrollToTop();
  }
    , [])
  return (
    <div className='contact-warp'>
      <div className="containers">
        <div className="contact-info">
          {/* <div className="row"> */}
          {/* single-info */}
          <div className="col-lg-4 ">
            <div className="single-info">
              <div className="content">
                <h3>+91 7305737390.</h3>
                <p>info@thebizlancer.com</p>
              </div>
            </div>
          </div>
          {/*/End single-info */}
          {/* single-info */}
          <div className="col-lg-4 col-12 ">
            <div className="single-info">
              <div className="content">
                <h3>bizlancer</h3>
                <p>Chennai - 600018.</p>
              </div>
            </div>
          </div>
          {/*/End single-info */}
          {/* single-info */}
          <div className="col-lg-4 col-12 ">
            <div className="single-info">
              <div className="content">
                <h3>Mon - Sun: 10am - 6pm</h3>
                <p>All day Open</p>
              </div>
            </div>
          </div>
          {/*/End single-info */}
          {/* </div> */}
        </div>
        <div className="inner">
          <div className="row md-row">
            <div className="col-lg-6">
              <div className='contact-us-left lft-cont'>
                <p className='tit'>Contact</p>
                <p className='pt'>Get in touch <br /> with us</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-us-form">
                <p>If you have any queries please fell free to contact with us.</p>
                {/* Form */}
                <form className="form" ref={form} onSubmit={sendEmail} method="post" action="mail/mail.php">
                  <div className="row">
                    <div className="col-lg-12 form-group">
                      <div className="form-group">
                        <TextField
                          className='contact-text-feild'
                          label="Name"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          type="text"
                          name="user_name"
                          value={emailData.user_name}
                          onChange={handleChange}
                          error={errors.user_name}
                          helperText={errors.user_name ? 'First Name is required' : ' '}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <TextField
                          className='contact-text-feild'
                          label="Email"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          type="email"
                          name="user_email"
                          value={emailData.user_email}
                          onChange={handleChange}
                          error={errors.user_email}
                          helperText={errors.user_email ? 'Email is required' : ' '}
                          inputProps={{
                            autoComplete: 'off',
                            autoCapitalize: 'none',
                            spellCheck: 'false',
                            style: { textTransform: 'lowercase' }
                          }}                
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <TextField
                          className='contact-text-feild'
                          label="Subject"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          type="text"
                          name="subject"
                          value={emailData.subject}
                          onChange={handleChange}
                          error={errors.subject}
                          helperText={errors.subject ? 'Email is required' : ' '}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <TextField
                          className='contact-text-feild'
                          label="How Can We Help You?"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          multiline
                          type="text"
                          rows={3}
                          name="message"
                          value={emailData.message}
                          onChange={handleChange}
                          error={errors.message}
                          helperText={errors.message ? 'Message is required' : ' '}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="login-btn">
                        <Button className="btn" type="submit" variant="contained" color="primary" onClick={sendEmail}>Submit</Button>
                      </div>
                    </div>
                  </div>
                </form>
                {/*/ End Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <ToastContainer />
    </div>
  )
}
