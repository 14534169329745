import React, { useEffect, useMemo, useRef } from 'react'
import EmployeeImg from '../../assets/images/employe.jpg'
import WhoWeAre from '../../assets/images/who-we-are.jpg'
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import { IconButton } from "@mui/material";
import './styles.scss'
import useInView from '../../components/UseInView';

export const HomePage: React.FC = () => {
    const [addRef, inView] = useInView({ threshold: 0.1 });

    // Initialize refs at the top level
    const ref1 = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);

    // Use useMemo to create an array of refs
    const imgRefs = useMemo(() => [ref1, ref2], [ref1, ref2]);

    useEffect(() => {
        imgRefs.forEach(ref => {
            if (ref.current) {
                addRef(ref);
            }
        });
    }, [imgRefs, addRef]);

    React.useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        };
        scrollToTop();
    }
        , [])
    return (

        <div className='page-wrapper' >
            <div className='hr-banner-img slideInFromLeftTitle-img'></div>
            <div className='content-img-wrapper' ref={imgRefs[0]}>
                <img src={EmployeeImg} alt='welcome' className={`slideInFromLeft-img ${inView[0] ? 'active' : ''}`} />
                <div className='welcome-content'>
                    <h3>Welcome to Bizlancer - Your Partner in Business Success</h3>
                    <p>
                        At Bizlancer, we are passionate about helping businesses flourish and excel in today's competitive landscape. We understand that running a successful business is not just a journey; it's a partnership built on trust, expertise, and shared goals. Our dedicated team works tirelessly to provide innovative solutions and personalized support, ensuring your business reaches its full potential. Join us, and let's achieve greatness together.
                    </p>
                </div>
            </div>
            <div className="container">
                <div className="schedule-inner">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 ">
                            {/* single-schedule */}
                            <div className="single-schedule first">
                                <div className="inner">
                                    <div className="single-content">
                                        <h4>Our Expert Team</h4>
                                        <p className='subtitle-content'>
                                            Our team of seasoned professionals is our greatest asset, bringing extensive expertise and a wide range of skills to every project. Driven by a shared mission to help your business flourish, our experts are more than just consultants; they are your dedicated partners in success.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            {/* single-schedule */}
                            <div className="single-schedule middle">
                                <div className="inner">
                                    <div className="single-content">
                                        <h4>Our Approach</h4>
                                        <p className='subtitle-content'>
                                            Our strength lies in our team of experienced professionals who bring a wealth of knowledge and diverse skills to the table. Our experts are driven by a common purpose - to see your business thrive. We're not just consultants. we're your allies in growth.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-12">
                            {/* single-schedule */}
                            <div className="single-schedule last">
                                <div className="inner">
                                    <div className="single-content">
                                        <h4>Our Services</h4>
                                        <p className='subtitle-content'>
                                            Bizlancer offers a wide range of services, from business consulting and strategy development to digital marketing and technology solutions. We are here to provide the guidance and tools you need to stay ahead of the competition and achieve your business objectives.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 col-12">
                            {/* single-schedule */}
                            <div className="single-schedule last">
                                <div className="inner">
                                    <div className="single-content">
                                        <h4>Join Us on Your Journey to Success</h4>
                                        <p className='subtitle-content'>
                                            Whether you're a startup looking for guidance or an established business seeking to reinvent itself, Bizlancer is here to guide you. We invite you to partner with us on your journey to success. Let's work together to write the next chapter of your business story.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='content-img-wrapper content-2-wrapper' ref={imgRefs[1]}>
                <div className='welcome-content'>

                    <div className='contant-us-card'>
                        <p className='ques-content'>
                            Have questions, or ready to take the next step? Contact us today to learn more about how Bizlancer can help your business thrive.
                        </p>

                        <p className='thanks-content'>
                            Thank you for considering Bizlancer as your trusted partner in business success.
                            Feel free to customize and expand upon this draft as needed to better reflect your company's unique story and values.
                        </p>

                        <div className='mail-phone-wrapper'>
                            <div className='mail-wrap'>
                                <a href="mailto:info@thebizlancer.com  ">
                                    <MailIcon width="16" height='16' className='mail-icon' />
                                    info@thebizlancer.com
                                </a>
                            </div>
                            <div className='contact-icon-wrapper'>
                                <a href="tel:+917305737390">
                                    <IconButton className="icon">
                                        <PhoneIcon />
                                    </IconButton>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={WhoWeAre} alt='who we are' className={`slideInFromRight-img ${inView[1] ? 'active' : ''}`} />
            </div>
        </div>
    )
}
