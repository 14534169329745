import React, { useEffect, useMemo, useRef } from 'react'
import HRSolutionImg from '../../assets/images/hrbanner.jpeg'

import './styles.scss'
import useInView from '../../components/UseInView';

export const OverseasConsultantPage = () => {
  const [addRef, inView] = useInView({ threshold: 0.1 });

  // Initialize refs at the top level
  const ref1 = useRef<HTMLDivElement>(null);

  // Use useMemo to create an array of refs
  const imgRefs = useMemo(() => [ref1], [ref1]);

  useEffect(() => {
    imgRefs.forEach(ref => {
      if (ref.current) {
        addRef(ref);
      }
    });
  }, [imgRefs, addRef]);

  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
    scrollToTop();
  }
    , [])
  return (
    <div>
      <div className='content-img-wrapper' ref={imgRefs[0]}>
        <img className={`hr-solution-img slideInFromLeft-img ${inView[0] ? 'active' : ''}`} src={HRSolutionImg} alt='HR' />
        <div className='hr-soultions-content'>
          <h3>Welcome to Bizlancer - Your Trusted Overseas Consultant</h3>
          <p className='hr-solutions-description'>
            At Bizlancer, we are your dedicated partner on the journey to exploring global opportunities and realizing your dreams of studying, working, or immigrating abroad. As seasoned overseas consultants, we take pride in providing comprehensive and personalized services to individuals and families seeking a seamless transition to a new chapter in their lives.
          </p>
          <h3>Our Commitment to Your Global Journey</h3>
          <p className='hr-solutions-description'>
            At Bizlancer, our commitment extends beyond providing services. it's about becoming your steadfast companion on your global journey. We understand that each individual's path is unique, and that's why our seasoned team of overseas consultants is dedicated to crafting personalized strategies that align with your aspirations
          </p>
          <h3>Your Success, Our Triumph</h3>
          <p className='hr-solutions-description'>
            At Bizlancer, we measure our success by your achievements. We take pride in being a part of your success story, whether it's pursuing higher education, securing employment overseas, or settling in a new country. Your triumphs are our triumphs.
          </p>
        </div>
      </div>
      <div className='cnt-wrapper'>
        <h2>Why Choose Bizlancer</h2>
        <div>
          <h3>Expert Guidance</h3>
          <p>Our team of experienced consultants brings in-depth knowledge of immigration and education policies, ensuring you receive accurate and up-to-date information. Each consultant brings a wealth of experience, staying abreast of the ever-evolving policies to provide you with accurate, up-to-date information. Whether you are planning to study, work, or immigrate, our consultants are dedicated to ensuring that you make informed decisions, paving the way for a successful and hassle-free journey.</p>
        </div>
        <div>
          <h3>Tailored Solutions</h3>
          <p>We understand that every individual has unique aspirations and challenges. Our services are customized to suit your specific needs, whether you're pursuing education, employment, or permanent residency. Recognizing the distinct aspirations and challenges of each individual, Bizlancer takes pride in offering tailored solutions that echo your unique journey. Our understanding goes beyond generic services. it delves into the intricacies of your goals, whether it's educational pursuits, career advancement, or the quest for permanent residency.</p>
        </div>
        <div>
          <h3>Global Network</h3>
          <p>With a vast network of connections worldwide, we have established relationships with reputable educational institutions, employers, and immigration authorities, opening doors to a multitude of opportunities. At Bizlancer, our commitment to providing unparalleled service extends beyond borders. Our global network is not just a collection of contacts. it's a dynamic ecosystem designed to empower your international journey</p>
        </div>
      </div>

      <div className='cnt-wrapper'>
        <h2>Our Services</h2>
        <div className='cnt-edu'>
          <div>
            <h3>Education Consulting</h3>
            <ul >
              <li>Guidance on choosing the right course and institution.</li>
              <li>Assistance with application processes.</li>
              <li>Visa application support.</li>
            </ul>
          </div>
          <div>
            <h3>Work and Immigration Services</h3>
            <ul >
              <li>Job placement assistance.</li>
              <li>Immigration consulting for various countries.</li>
              <li>Permanent residency application support.</li>
            </ul>
          </div>
          <div>
            <h3>Language Proficiency and Test Preparation</h3>
            <ul >
              <li>Language proficiency assessment and training.</li>
              <li>Test preparation resources for exams such as IELTS, TOEFL, and more.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='cnt-wrapper'>
        <h3>Your Success is Our Priority</h3>
        <p>At Bizlancer, our mission is to empower individuals to achieve their goals of international education, employment, and settlement. We take pride in being a reliable source of guidance, support, and expertise throughout your journey.</p>
      </div>
      <div className='cnt-wrapper'>
        <h3>Get Started Today</h3>
        <p>Embark on your global journey with confidence. Contact Bizlancer today to begin the exciting process of realizing your overseas dreams. Whether you're a student, a professional, or a family seeking a new adventure, we are here to make your aspirations a reality.</p>
        <p>Join us in making the world your oyster.</p>
      </div>
    </div>
  )
}
