// Loading.tsx
import React from 'react';
import BlinkingIcon from './BlinkingIcon';

const Loading: React.FC = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <BlinkingIcon />
    </div>
  );
};

export default Loading;
