import React, { useEffect, useMemo, useRef } from 'react'
import HRBannerImg from '../../assets/images/hr/demonstration.jpg'
import HRParticipationImg from '../../assets/images/hr/participation.jpg'
import HRFocusImg from '../../assets/images/hr/focus.jpg'
import useInView from '../../components/UseInView';
import './styles.scss'

export const HRSolutonPage = () => {
  const [addRef, inView] = useInView({ threshold: 0.1 });

  // Initialize refs at the top level
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const ref3 = useRef<HTMLDivElement>(null);

  // Use useMemo to create an array of refs
  const imgRefs = useMemo(() => [ref1, ref2, ref3], [ref1, ref2, ref3]);

  useEffect(() => {
    imgRefs.forEach(ref => {
      if (ref.current) {
        addRef(ref);
      }
    });
  }, [imgRefs, addRef]);

  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
    scrollToTop();
  }
    , [])
  return (
    <div className='hr-solution'>
      <div className='content-img-wrapper' ref={imgRefs[0]}>
        <img className={`hr-solution-img slideInFromLeft-img ${inView[0] ? 'active' : ''}`} src={HRBannerImg} alt='HR' />
        <div className='hr-soultions-content'>
          <h3>Welcome to Bizlancer - HR Solutions</h3>
          <p className='hr-solution-quote'>Excellence Through People</p>

          <p className='hr-solutions-description'>
            Bizlancer HR Solutions, is a lead Professional Management Service provider focusing on Executive Resourcing, Management Consultants and Manpower Outsourcing in the field of IT, Manufacturing and Service industries. We have been successfully offering our services to Engineering & Automobiles, FMCG, Plastic Product Manufacturing, Construction and Real Estate, Textile & Apparels, EHS Process Products, Electronics, Food Product Manufacturing, Research & Development, Pharmaceutical, Insurance Companies, etc.
          </p>
          <p className='hr-solutions-description'>
            Our continued and dedicated focus on satisfying customer needs and our commitment to remain resolute on the ethics of workmanship, make us one of the best development companies in Chennai. As a workplace we strive to provide an inclusive, accomodating environment where creativity and excellence thrive.
          </p>
        </div>
      </div>
      <div className='out-content'>
        <div className='our-vision'>
          <h3>Our Vision</h3>
          <p>Empowering Organizations, Enriching Lives and our vision is to be the foremost catalyst in shaping workplaces where individuals thrive, and businesses flourish. We envision a future where our innovative HR solutions redefine industry standards, fostering a culture of continuous growth, inclusivity, and unparalleled success for our clients.</p>
        </div>
        <div className='our-mission'>
          <h3>Our Mission</h3>
          <p>Our mission is to revolutionize HR solutions by delivering tailored excellence, fostering strategic partnerships, and embracing innovation. We are dedicated to empowering organizations through ethical leadership, continuous learning, and a commitment to employee empowerment.</p>
        </div>
      </div>

      <div ref={imgRefs[1]}>
        <h3>Who We Are</h3>
        <div className='content-img-wrapper'>
          <div className='hr-soultions-content'>
            <p className='hr-solution-quote'>Who We Are at Bizlancer in the HR Solution Industry</p>
            <p className='hr-solutions-description'>
              We are a dynamic and forward-thinking HR solution provider committed to reshaping the future of work. Our team at Bizlancer is driven by a shared passion for empowering organizations and enhancing the employee experience. With a wealth of expertise in human resources, strategic planning, and cutting-edge technologies, we stand as a reliable partner for businesses seeking innovative solutions to their HR challenges.
            </p>
            <p className='hr-solutions-description'>
              Our approach is rooted in a client-centric mindset, emphasizing collaboration, transparency, and a genuine understanding of each client's unique needs. At Bizlancer, we pride ourselves on fostering a culture of excellence, continuous learning, and ethical leadership. Join us in the journey to transform your workforce and achieve sustainable success.</p>
          </div>
          <img className={`hr-solution-img slideInFromRight-img ${inView[1] ? 'active' : ''}`} src={HRParticipationImg} alt='HR' />
        </div>
      </div>
      <div>
        <h2 className='head-hr-solution'>Our Approach</h2>
        <div className='content-img-wrapper' ref={imgRefs[2]}>
          <img className={`hr-solution-img slideInFromLeft-img ${inView[2] ? 'active' : ''}`} src={HRFocusImg} alt='HR' />
          <div className="col-12">
            <div className="blog-comments">
              <div className="comments-body">
                <div className="single-comments">
                  <div className="main">
                    <div className="body">
                      <h2>Understanding Your Business</h2>
                      <p className='hr-solutions-description'>
                        We conduct an in-depth analysis of your business, culture, and specific HR challenges. We conduct in-depth analyses of market trends, competitor landscapes, and industry shifts, ensuring that our strategies are not only tailored to your business but are also responsive to external forces.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="single-comments">
                  <div className="main">
                    <div className="body">
                      <h2>Continuous Learning Partnership</h2>
                      <p className='hr-solutions-description'>
                        Our understanding is not static; it's a dynamic partnership rooted in continuous learning. As your business evolves, so does our comprehension. We actively seek feedback, engage in ongoing conversations, and adapt our strategies to align with the ever-changing landscape of your organization.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="single-comments">
                  <div className="main">
                    <div className="body">
                      <h2>Implementation and Support</h2>
                      <p className='hr-solutions-description'>
                        We work closely with your team to implement proposed solutions, providing ongoing support for seamless integration.
                      </p></div>
                  </div>
                </div>
                <div className="single-comments left">
                  <div className="main">
                    <div className="body">
                      <h2>Continuous Evaluation</h2>
                      <p className='hr-solutions-description'>
                        Our commitment extends beyond implementation. We continuously evaluate the effectiveness of our solutions, making adjustments for optimal results.
                      </p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
