import { useEffect, useRef, useState, RefObject } from 'react';

interface UseInViewOptions {
  threshold?: number;
  root?: Element | null;
  rootMargin?: string;
}

const useInView = (options: UseInViewOptions) => {
  const refs = useRef<RefObject<HTMLElement>[]>([]);
  const [inView, setInView] = useState<boolean[]>([]);

  useEffect(() => {
    if (refs.current.length === 0) return;

    const observers = refs.current.map((ref, index) => {
      if (!ref.current) return null;

      const observer = new IntersectionObserver(([entry]) => {
        setInView(prev => {
          const updated = [...prev];
          updated[index] = entry.isIntersecting;
          return updated;
        });
      }, options);

      observer.observe(ref.current);

      return observer;
    }).filter(observer => observer !== null) as IntersectionObserver[];

    return () => {
      observers.forEach(observer => observer.disconnect());
    };
  }, [options]);

  const addRef = (ref: RefObject<HTMLElement>) => {
    refs.current.push(ref);
  };

  return [addRef, inView] as const;
};

export default useInView;
