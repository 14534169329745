import React, { useEffect, useMemo, useRef } from 'react'
import ExcellenceImg from '../../assets/images/excellence.png'
import BussinessImg from '../../assets/images/bussiness.png'
import DigitalImg from '../../assets/images/digital.png'
import ProductImg from '../../assets/images/product.png'
import StartupImg from '../../assets/images/startup.png'
import TeamImg from '../../assets/images/team.png'

import './styles.scss'
import useInView from '../../components/UseInView'

export const InstitutionsPage = () => {
  const [addRef, inView] = useInView({ threshold: 0.1 });

  // Initialize refs at the top level
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const ref3 = useRef<HTMLDivElement>(null);
  const ref4 = useRef<HTMLDivElement>(null);
  const ref5 = useRef<HTMLDivElement>(null);
  const ref6 = useRef<HTMLDivElement>(null);

  // Use useMemo to create an array of refs
  const imgRefs = useMemo(() => [ref1, ref2, ref3, ref4, ref5, ref6], [ref1, ref2, ref3, ref4, ref5, ref6]);

  useEffect(() => {
    imgRefs.forEach(ref => {
      if (ref.current) {
        addRef(ref);
      }
    });
  }, [imgRefs, addRef]);

  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
    scrollToTop();
  }
    , [])
  return (
    <div>
      <div className='content-img-wrapper cln-r' ref={imgRefs[0]}>
        <img className={`excelence-img slideInFromLeft-img ${inView[0] ? 'active' : ''}`} src={ExcellenceImg} alt='HR' />
        <div className='hr-soultions-content'>
          <h3>Welcome to Bizlancer - Institutions</h3>
          <p className='hr-solution-quote'>Excellence Through People</p>

          <p className='hr-solutions-description'>
            Bizlancer HR Solutions, is a lead Professional Management Service provider focusing on Executive Resourcing, Management Consultants and Manpower Outsourcing in the field of IT, Manufacturing and Service industries. We have been successfully offering our services to Engineering & Automobiles, FMCG, Plastic Product Manufacturing, Construction and Real Estate, Textile & Apparels, EHS Process Products, Electronics, Food Product Manufacturing, Research & Development, Pharmaceutical, Insurance Companies, etc.
          </p>
        </div>
      </div>

      <div>
        <div className='content-img-wrapper cln-r' ref={imgRefs[1]}>
          <img className={`instits-img slideInFromLeft-img ${inView[1] ? 'active' : ''}`} src={ProductImg} alt='HR' />
          <div className='hr-soultions-content'>
            <h2>Product Engineering</h2>
            <p>
              Creating a product requires a meticulous understanding of the market, the customers, the users will handle the product and more importantly, the real-life problem that the product will solve. Our multifaceted expertise in engineering, marketing, and customer relationships help us create products that are best suited for your needs and maybe even exceed your expectations!
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className='content-img-wrapper' ref={imgRefs[2]}>
          <div className='hr-soultions-content'>
            <h2>Digital Transformation</h2>
            <p>Gone are the days when people were haggling with information stored on papers! Today, we are looking at almost all facets of business transcending into the digital realm. It makes the process of business convenient, accurate and also makes it easier to acquire the essential intelligence from structured data. We help in the transfiguration of your business from the classical to the digital realm without compromising On The authenticity and values of your business.
            </p>
          </div>
          <img className={`instits-img slideInFromRight-img ${inView[2] ? 'active' : ''}`} src={DigitalImg} alt='HR' />
        </div>
      </div>
      <div>
        <div className='content-img-wrapper cln-r' ref={imgRefs[3]}>
          <img className={`instits-img slideInFromLeft-img ${inView[3] ? 'active' : ''}`} src={BussinessImg} alt='HR' />
          <div className='hr-soultions-content'>
            <h2>Business Incubation</h2>
            <p>There are some great ideas that have been orphaned without the right guidance and resources. We take care not just to give the businesses, the space, and the resources but also the guidance and mentorship that will elevate their business to the next level!
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className='content-img-wrapper' ref={imgRefs[4]}>
          <div className='hr-soultions-content'>
            <h2>Extended Team</h2>
            <p>Sometimes your ideas and your vision might be much larger than the strength of your team, both in terms of skills and numbers! Let your native team strength not hamper the extent of your vision. We give you the option to extend your team and utilize our skill sets for your product development efforts!
            </p>
          </div>
          <img className={`instits-img slideInFromRight-img ${inView[4] ? 'active' : ''}`} src={TeamImg} alt='HR' />
        </div>
      </div>
      <div>
        <div className='content-img-wrapper cln-r' ref={imgRefs[5]}>
          <img className={`instits-img slideInFromLeft-img ${inView[5] ? 'active' : ''}`} src={StartupImg} alt='HR' />
          <div className='hr-soultions-content'>
            <h2>StartUp Services</h2>
            <p>For many aspiring entrepreneurs there is nothing that gives bigger joy than the joy of starting up, and at the same time, the stumbling blocks that they have to face in achieving their dreams are aplenty as well! We aspire to minimize the obstacles and maximize the joy of starting up!!
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
