import React, { useState } from 'react'
import Logo from '../../assets/images/logo.png';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import './styles.scss'
import { NavLink } from 'react-router-dom';

export const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const MenuLinks = ({closeOnClick}:any) => {
    return (
      <>
        <NavLink to='/home' onClick={closeOnClick} className={({isActive}) => isActive ? 'activeMenu' : ''}>
          Home
        </NavLink>
        <NavLink to={'/hr-solution'}  onClick={closeOnClick} className={({isActive}) => isActive ? 'activeMenu' : ''}>
          HR Solution
        </NavLink>
        <NavLink to={'/overseas-consultant'}  onClick={closeOnClick} className={({isActive}) => isActive ? 'activeMenu' : ''}>
          Overseas Consultant
        </NavLink>
        <NavLink to={'/institutions'}  onClick={closeOnClick} className={({isActive}) => isActive ? 'activeMenu' : ''}>
          Institutions
        </NavLink>
        <NavLink to={'/contact-us'}  onClick={closeOnClick} className={({isActive}) => isActive ? 'activeMenu' : ''}>
          Contact Us
        </NavLink>
      </>
    )
  }

  return (
    <header className='header'>
      <div className='header-wrap'>
        <NavLink to={'/home'}>
          <img className='logo-wrap' src={Logo} alt='logo' />
        </NavLink>
        <div className='menu-wrapper'>
          <MenuLinks />
        </div>
        <div className='mobile-menu' onClick={() => handleMenuClick()}>
          {!menuOpen ? <MenuOutlinedIcon /> : <ClearOutlinedIcon />}
        </div>
      </div>
      {menuOpen &&
        <>
          <div className='mobile-menu'>
            <MenuLinks closeOnClick={handleMenuClick}/>
          </div>
        </>}
    </header>

  )
}
