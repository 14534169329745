import React from 'react';
import LogoIcon from '../../assets/images/logo-icon.png';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import BusinessIcon from '@mui/icons-material/Business';

import './styles.scss'
import { SocialMediaIcons } from '../SocialMediaIcons';
import { NavLink } from 'react-router-dom';

export const Footer: React.FC = () => {
  return (
    <div className='footer'>
      <div>
        <div className='about-us-wrapper'>
          <img className='logo-wrap' src={LogoIcon} alt='logo' />
          <h3>About Us</h3>

        </div>
        <p className='about-us-content'>
          At Bizlancer, we are passionate about helping businesses flourish and excel in today's competitive landscape. We understand that running a successful business is not just a journey. it's a partnership built on trust, expertise, and shared goals.
        </p>
      </div>

      <div className='quick-links'>
        <h3>Quick Links</h3>
        <NavLink to={'/hr-solution'}>
          HR Solution
        </NavLink>
        <NavLink to={'/overseas-consultant'}>
          Overseas Consultant
        </NavLink>
        <NavLink to={'/institutions'}>
          Institutions
        </NavLink>
        <NavLink to={'/contact-us'}>
          Contact Us
        </NavLink>
      </div>

      <div>
        <h3>Contact Us</h3>
        <p className='footer-address'>
          <BusinessIcon />
          Chennai - 600018.
        </p>
        <p className='footer-mobile'>
          <a href="tel:+917305737390">
            <PhoneIcon />
          </a>
          <a href="tel:+917305737390">
            +91 7305737390.
          </a>
        </p>
        <p className='footer-mail'>
          <a href="mailto:info@thebizlancer.com  ">
            <MailIcon />
          </a>
          <a href="mailto:info@thebizlancer.com  ">
            info@thebizlancer.com  
          </a>
        </p>

        <SocialMediaIcons />

      </div>

    </div>
  )
}
